
/*  document typefaces */
@mixin spectralLight() {
	font-family:'Spectral', serif; 
	font-weight: 300;
} 

@mixin spectralRegular() {
	font-family:'Spectral', serif; 
	font-weight: 400;
} 

@mixin spectralBold() {
	font-family:'Spectral', serif; 
	font-weight: 700;
} 

@mixin sourceSansRegular() {
	font-family:'Source Sans Pro', sans-serif; 
	font-weight: 400;
} 

@mixin sourceSansBold() {
	font-family:'Source Sans Pro', sans-serif; 
	font-weight: 700;
} 


/*  document colors */
$greyOne: #FCF8F7;
$greyTwo: #EDE9E4;
$greyThree: #E0DAD3;
$greyFour: #A19B94;
$greyFive: #312E2A;
$greySix: #140D0F;
$greySeven: #252231;

$gold: #E39A3B;
$yellow: #F3E331;
$lightRed: #DE7349;
$red: #DE3E16;
$darkHover: #2e2c3a;

$black: #000000;
$white: #ffffff;


/*  custom break points  */
$desktopXLarge: 1500px;
$desktopLarge: 1400px;
$desktop: 1200px;
$desktopSmall: 1024px;
$desktopXSmall: 950px;
$tablet: 768px;
$mobileMedium: 500px;
$mobile: 375px;
$mobileSmall: 320px;



/*  document mixins */
@mixin transition($transition...) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin respond-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin respond-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}


