/*  document tyopgraphy */

h1 {
	@include spectralLight;
	font-size: 48px;
	color: $greySeven;
	&.big {
		font-size: 61px;
	}
	@include respond-max(575px) {
		font-size: 40px;
	}
}

h2 {
	@include spectralBold;
	font-size: 39px;
	letter-spacing: -3px;
	line-height: 59px;
	color: $greySeven;
	@include respond-max(575px) {
		line-height: 42px;
	}
}

h3 {
	@include sourceSansRegular;
	font-size: 31px;
	color: $greySeven;
}

h4 {
	@include sourceSansBold;
	font-size: 25px;
	line-height: 32px;
	color: $greySeven;
}

h5 {
	@include sourceSansBold;
	font-size: 20px;
	line-height: 25px;
	color: $greySeven;
	letter-spacing: -.5px;
}

h6 {
	@include spectralBold;
	font-size: 20px;
	line-height: 25px;
	color: $greySeven;
}

p {
	@include spectralRegular;
	font-size: 16px;
	line-height: 1.5rem;
	letter-spacing: -.5px;
	color: $greySeven;
	&.large {
		font-size: 1.5rem;
		line-height: 2rem;
	}
	&.small {
		font-size: 13px;
	}
	&.extra-small {
		font-size: 11px;
	}
	&.bold, .bold {
		@include spectralBold;
	}
}

a.text-link {
	@include sourceSansBold;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	color: $greyFive;
	text-decoration: none;
	position: relative;
	&:hover {
		text-decoration: underline;
	}

	&.red {
		span {
			position: relative;
		}
		&:before {
			content: '';
			width: 0;
			height: 22px;
			background: $lightRed;
			position: absolute;
			left: -6px;
			top: -2px;
			@include transition(.2s);
		}
		&:hover {
			text-decoration: none;

			&:before {
				width: calc(100% + 12px);
				@include transition(.2s);
			}
		}
	}
}


h1, h2, h3, h4, h5, h6, p, a {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
}

.content-header {
	@include sourceSansBold;
	font-size: 14px;
	color: #757369;
	text-transform: uppercase;
	letter-spacing: .2px;
	margin-right: 14px;
	display: inline-block;
}
.content-content {
	display: inline-block;
}