#new-releases {
	#section-header {
		.header-wrapper {
			.section-header-links {
				@include respond-max(991px) {
					margin-bottom: 20px;
				}
			}
		}
	}
}

#coming-soon {
	margin-top: 50px;
	@include respond-max(991px) { 
		margin-top: 0px;
	}
	#section-header .section-header-links {
		margin-bottom: 20px;
	}
}



body.layout_home {
	@include respond-min(991px){
		#mainNav {
			background-image: none;
			height: auto;
			padding: 2rem 15px;	
			div.nav {
				height: auto;
			}
			.site-logo {
				height: 84px;
				width: 200px;
				overflow: hidden;
              	position: relative;
				svg {
					height: 84px;
					width: 1000px;
					position: absolute;
					top: 0;
					//left: 1px;
				}
				&:hover {
					svg, img {
						left: -200px;
						animation: fullNavLogoHover 0.7s steps(4) infinite;
					}
				}
				.darkmode {
					display: none;
				}
			}
		}
	}
	
	.main-content {
		background-image: url('/assets/images/bg-shade_5.png');
		background-position: 200% 0%;
	    background-size: 80%;
		background-repeat: no-repeat;
	}
	.main-content ~ .main-content {
		background-image: url('/assets/images/bg-shade_5.png');
		background-position: 0% 80%;
	    background-size: 80%;
		background-repeat: no-repeat;
	}

	#latest-news {
		#section-header .section-header-links {
			@include respond-min(992px) {
				margin-bottom: 20px;
			}
		}
	}
}

@keyframes fullNavLogoHover {
	100% { left: -1000px; }
}