#coming-soon.upcoming-page {
	.coming-soon {
		padding: 0;
		margin: 0 auto 50px;
		&:last-of-type {
			margin-bottom: 0;
		}
		.content-date {
			position: relative;
			background-image: none;
			color: #D53C15;
			font-size: 18px;
			line-height: 20px;
			padding: 0;
			margin-bottom: 20px;
		}
		.format {
			display: block;
		}

		h4 {
			font-size: 30px;
			line-height: 39px;
			margin-bottom: 0;
		}

		.content-background {
			background: $white;
			.content-blur, .content-blur-wrapper, .content-splatter {
				display: none;
				@include respond-max(991px) {
					display:block;
				}
			}
		}
		.content-image {
			width: 290px;
			padding-left: 60px;
			border: none;
			img {
				top: 30px;
				@include respond-max(991px) { 
					top: 0;
				}
			}
			@include respond-max(991px) { 
				width: 100%;
				justify-content: flex-start;
				padding: 30px 22px 0;
				height: auto;
			}	
		}
		.content-block-content {
			width: calc(100% - 290px);
			padding: 30px 60px 30px 40px;
			@include respond-max(991px) { 
				width: 100%;
				padding: 30px 22px;
			}
			.content-description {
				height: auto;
				p {
					height: auto;
				}
				.content-blend {
					display: none;
				}
			}
		}
		.content-splatter {
			display: block;
		}
		
		.content-links {
			display: none;
		}
		@include respond-min(992px) { 
			
		}		
	}
	
}