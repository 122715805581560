#faq.faq-page {
  h4.section-header {
    @include spectralBold;
    font-size: 39px;
    letter-spacing: -3px;
    line-height: 39px;
    margin-bottom: 0;
  }

  .question-wrapper{
    position: relative;
    cursor: pointer;

    .chevron {
      width: 20px;
      height: 20px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      transform: rotate(-90deg);
      top: 3px;
      @include transition(.1s);
      @include respond-max(991px){
        position: absolute;
        top: 0%;
        left: 0%;
        height: 25px;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 80px;
      }
    }
    .question{
      display: inline-block;
      @include respond-max(991px){
        display: block;
        max-width: calc(100% - 30px);
        margin-left: 24px;
      }
      @include respond-max(767px){
        max-width: calc(100% - 24px);
      }
    }
    .answer{
      display: none;
      padding-left: 24px;
      p {
        margin-bottom: 0;
      }
    }
    &.open {
      .chevron {
        transform: rotate(0deg);
        @include transition(.1s);
      }
    }
    &:hover {
      .chevron svg {
        animation: smallSpriteHover 0.5s steps(3) infinite;
        fill: $red;
        @include respond-max($tablet) {
          fill: #000;
          animation: none;
        }
      }
    }
    .bold {
      font-weight: bold;
    }
    .snippet{
      background: $greyTwo;
      max-width: 530px;
      padding: 1rem;
      font-family: "monospace";
    }

    .copy-link {
      position: relative;
      width: 20px;
      height: 22px;
      top: 3px;
      display: inline-block;
      overflow: hidden;
      @include respond-max($tablet) {
        display: none;
      }
      svg {
        width: 80px;
        height: 22px;
        position: absolute;
        left: 0;
      }
      &:hover {
        svg {
          animation: smallSpriteHover 0.5s steps(3) infinite;
          fill: $red;
        }
      }
    }

  }
}