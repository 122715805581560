.content-block {
	
	.content-block-content {
		background: $white;
		padding: 30px 22px;
		height: calc(100% - 200px);

		.format {
			display: none;
			@include sourceSansRegular;
			font-size: 12px;
			line-height: 12px;
			letter-spacing: .4px;
			text-transform: uppercase;
			color: #7F746E;
			margin-bottom: 4px;
		}

		.content-date {
			@include sourceSansRegular;
			color: #7F746E;
			font-size: 12px; 
			line-height: 15px;
			letter-spacing: .4px;
			margin-bottom: 8px;
		}
		.content-description {
			height: 140px;
			overflow: hidden;
			position: relative;
			margin-bottom: 16px;
			p {
				height: 150px;
				overflow: hidden;
				position: relative;
				@include respond-max(576px) {
					height: 130px;
				}
			}
			@include respond-max(576px) { 
				height: 115px;
			}
		}
		.content-links {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 38px;
		}
		.text-link {
			display: block;
			.fa-youtube {
				font-size: 18px;
				line-height: 19px;
				color: $red;
				vertical-align: middle;
				margin-left: 5px;
			}
		}
	}
	.content-image {
		height: 200px;
		position: relative;
		text-align: center;
		border-bottom: 6px solid $white;
		background: $black;
		.content-background {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			overflow: hidden;
		}
		.content-blur {
			width: 110%;
			height: 110%;
			left: -5%;
			top: -5%;
			position: absolute;
			background: $greyFive;
			background-size: cover;
			background-position: center;
		}
		.content-blur-wrapper {
			position: absolute;
			width: 100%;
			height: 100%;
			backdrop-filter: blur(8px);
			-webkit-backdrop-filter: blur(8px);
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				 background: $black;
				 opacity: .8;
			}
		}
		.content-splatter {
			position: absolute;
			bottom: -20px;
			width: 100%;
			left: 0;
			height: 35px;
			&:before {
				background-image: url('/assets/images/divider_3.png');
				width: 120%;
				height: 35px;
				position: absolute;
				bottom: 0px;
				content: '';
				left: -10%;
				background-size: cover;
			}
		}

		img {
			position: relative;
			top: -12px;
			z-index: 1;
			height: 230px;
			@include transition(.1s);
			border-radius: 2px;
		}
		.uk {
			position: absolute;
			color: $white;
			@include sourceSansRegular;
			font-size: 12px;
			opacity: .7;
			bottom: 14px;
			right: 10px;
			@include transition(.2s);
			&:hover {
				opacity: 1;
				cursor: pointer;
				@include transition(.2s);
			}
			@include respond-max(765px) {
				font-size: 16px;
				right: 20px;
				bottom: 20px;
			}
		}
	}
	&:nth-of-type(2n) {
		.content-image {
			.content-splatter:before {
				background-image: url('/assets/images/divider_2.png');
			}
		}
	}
	&:nth-of-type(3n) {
		.content-image {
			.content-splatter:before {
				background-image: url('/assets/images/divider_1.png');
			}
		}
	}
	&:nth-of-type(4n) {
		.content-image {
			.content-splatter:before {
				background-image: url('/assets/images/divider_4.png');
			}
		}
	}


	&.coming-soon {
		.content-block-content {
			//height: calc(100% - 220px);
			.content-description {
				height: 170px;
				@include respond-max(576px) { 
					height: 130px;
				}
				p {
					height: 100%;
				}
			}
			.content-links {
				height: auto;
			}
			.content-date {
				position: absolute;
				top: 6px;
				right: 0px;
				background-size: 100% 34px;
				background-position: center;
				background-image: url('/assets/images/coming-soon-date.png');
				@include sourceSansBold;
				font-size: 14px;
				line-height: 18px;
				color: $white;
				padding: 8px 20px;
				z-index: 2;
			}
		}
	}

	&.featured {
		@include respond-min(992px) { 
			margin-bottom: 50px;
			display: flex;
			flex-direction: row;
			.content-block-content {
				height: auto;
				width: calc(66.6667% + 20px);
				padding: 30px 100px;
				.content-description {
					height: auto;
					p {
						height: auto;
					}
					.content-blend {
						display: none;
					}
				}
				.content-links {
					height: auto;
				}
			}
			.content-image {
				border-bottom: 0;
				border-right: 3px solid $white;
				height: auto;
				//width: calc(33.3333% - 20px);
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
				padding-right: 15px;
				display: flex;
				justify-content: center;
				.content-splatter {
					width: 40px;
					right: -20px;
					top: 0;
					left: auto;
					height: 100%;
					&:before {
						bottom: -10%;
						left: 0;
						height: 120%;
						width: 40px;
						background-image: url('/assets/images/divider_5.png');
					}
				}
				img {
					height: 340px;
					top: calc(50% - 170px);
				}
			}
			&:nth-of-type(2n) {
				.content-image {
					.content-splatter:before {
						background-image: url('/assets/images/divider_6.png');
					}
				}
			}
			&:nth-of-type(3n) {
				.content-image {
					.content-splatter:before {
						background-image: url('/assets/images/divider_7.png');
					}
				}
			}
			&:nth-of-type(4n) {
				.content-image {
					.content-splatter:before {
						background-image: url('/assets/images/divider_8.png');
					}
				}
			}

		}
		
	}

	@include respond-max(991px) { 
		margin-bottom: 50px;
	}

	&:hover {
		.content-image {
			img {
				transform: scale(1.02);
				@include transition(.2s);
			}
		}
	}

}
