.sidebar {
	max-width: 190px;
	@include transition(.4s);

	@include respond-max(1200px) { 
		max-width: 160px;
	}
	a {
		color: $greySix;
		font-size: 16px;
		line-height: 20px;
		overflow: hidden;
		display: block;
		@include sourceSansRegular;
		max-width: 160px;
		@include respond-max(1200px) { 
			max-width: 130px;
		}
		&.active {
			@include sourceSansBold;
		}
		&:hover {
			@include sourceSansBold;
			text-decoration: none;
		}
	}
	.secondary-links {
		a {
			margin-top: 25px;
			display: block;
			&.sub-category {
				color: $greyFive;
				font-size: 14px;
				letter-spacing: -.1px;
				opacity: .6;
				margin-top: 0;
			}
		}
	}
	.sub-categories {
		margin-bottom: 25px;
		a {
			font-size: 25px;
			line-height: 28px;
			display: inline;
			position: relative;
			.title {
				position: relative;
				z-index: 1;
			}
			.splatter {
				position: absolute;
				width: 0%;
				height: 123%;
				overflow: hidden;
				left: calc(50%);
				opacity: .2;
				@include transition(.1s);
				transform: translateX(-50%);
				img {
					width: 100%;
					height: 100%;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
				&.one {
					top: -2px;
				}
				&.two {
					top: -1px;
				}
				&.three {
					top: 1px;
				}

				&.darkmode {
					opacity: 0;
				}
				&.active {
					opacity: 0;
				}
			}
			&.active, &:hover {
				color: $white;
				@include transition(.1s);
				text-decoration: none;
				@include sourceSansRegular;
				.splatter {
					@include transition(.1s);
					&.one {
						width: 180px;
						@include respond-max(1200px) {
							width: 100px;
						}
					}
					&.two {
						width: 125px;
					}
					&.three {
						width: 85px;
					}
				}
			}
			&.active {
				.splatter {
					&.active {
						opacity: 1;
						@include transition(.1s);
					}
				}
				&:hover {
					.splatter {
						&.hover {
							opacity: 1;
							@include transition(.1s);
						}
						&.active {
							opacity: 0;
							@include transition(.1s);
						}
					}
				}
			}
			&:hover {
				.splatter {
					&.hover {
						opacity: 1;
						@include transition(.1s);
					}
				}
			}
		}
	}
	& > div > span {
		display: block;
		margin-bottom: 8px;
	}
	&.stuck {
		position: fixed;
		box-sizing: content-box;
		top: 90px;
		z-index: 1;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			left: calc((100% - 1140px) / 2 - 15px);
		}
		@media all and (-ms-high-contrast: none) and (max-width: 1200px), (-ms-high-contrast: active) {
			left: calc((100% - 960px) / 2 - 15px);
		}
	}
	@include respond-max(991px) { 
		display: none;
	}
}	

.sidebar-spacer {
	display: none;

	&.show {
		@include respond-min(992px) { 
			display: block;
		}
	}
}

