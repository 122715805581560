#newsletter-signup {
	background: $yellow;
	background-image: url('/assets/images/bg-shade-black.png');
	background-repeat: no-repeat;
	background-size: 75% 100%;
	background-position: 160% 100%;
	margin-top: 50px;
	width: 100%;

	@include respond-max(767px) {
		background-image: url('/assets/images/darkmode/bg-shade_4.png');
		background-size: 125% 90%;
    	background-position: 0px 210%;
	}
	@include respond-max($mobile) {
		background-size: 125% 85%;
	}

	.newsletter-wrapper {
		align-items: center;
		@include respond-max(991px) { 
			justify-content: space-between;
		}
	}

	.big-text {
		max-width: 460px;
		p {
			@include spectralLight;
			font-size: 52px;
			line-height: 60px;
			letter-spacing: -3.5px;
			color: $greySix;
		}
		@include respond-max(991px) { 
			margin-right: 0px;
			max-width: 360px;
		}
		@include respond-max(767px) { 
			text-align: center;
			max-width: 100%;
			margin-top: 50px;
			p {
				font-size: 42px;
				line-height: 50px;
			}
		}
	}
	.signup-wrapper-outer {
		padding-top: 2rem;
    	padding-bottom: 2rem;
	}

	.form-wrapper-inner {
		max-width: 230px;
		@include respond-max(767px) {
			max-width: 100%;
		}
	}

}