.photo-gallery {
  position: relative;
  margin: 70px auto;
  .background-wrapper {
    background: $greyTwo;
    width: calc(100% + 60px);
    height: calc(100% + 40px);
    position: absolute;
    top: -20px;
    left: -30px;
    z-index: 0;
    @include respond-max(576px) {
      width: calc(100% + 30px);
      left: -15px;
    }
  }
  h2 {
    position: relative;
  }
  .owl-carousel {
    @include respond-max(576px) {
      padding: 0 10px;
    }
    .photo {
      width: 100%;
      margin-right: 12px;
      &:last-of-type {
        margin-right: 0;
      }
      img {
        width: auto;
        height: 170px;
        @include respond-max(1200px) {
          height: 150px;
        }
      }
      p {
        @include sourceSansRegular;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}