#mainNav, #sticky-nav {
	height: 74px;
	background-color: $white;
	background-color: #fff;
	background-image: url(/assets/images/bg-shade_5.png);
	background-size: 50%;
	background-position: left center;
	background-repeat: no-repeat;
	z-index: 101;
	position: relative;

	div.nav {
		justify-content: space-between;
		align-items: center;
		height: 74px;
		overflow: hidden;
		flex-wrap: nowrap;

		.site-logo {
			height: 54px;
			width: 128px;
			overflow: hidden;
			position: relative;
			svg {
				height: 54px;
				width: 640px;
				position: absolute;
				top: 0;
			}
			&:hover {
				svg {
					left: -128px;
					animation: stickyNavLogoHover 0.7s steps(4) infinite;
				}
			}
			.darkmode {
				display: none;
			}
		}

		.nav-link {
			color: $greySix;
			font-size: 16px;
			letter-spacing: 2px;
			@include sourceSansBold;
			text-transform: uppercase;
			position: relative;
			z-index: 1;
			@include transition(.1s);
		}

		.nav-item {
			position: relative;
			opacity: 1;
			@include transition(.3s);
			.splatter {
				position: absolute;
				width: 0%;
				height: 100%;
				overflow: hidden;
				top: 7px;
				left: 50%;
				transform: translateX(-50%);
				opacity: .2;
				@include transition(.1s);
				img {
					width: 100%;
				}
				&.one {
					top: 4px;
					img {
						transform: rotate(180deg);
					}
				}
				&.three {
					top: 4px;
				}
				&.five {
					top: 9px;
				}
				&.six {
					top: 6px;
				}
				&.darkmode {
					opacity: 0;
				}
				&.active {
					opacity: 0;
				}
			}
			&.active, &:hover {
				.nav-link {
					color: $white;
					@include transition(.1s);
				}
				.splatter {
					width: 100%;
					@include transition(.1s);
				}
			}
			&.active {
				.splatter {
					&.active {
						opacity: 1;
						@include transition(.1s);
					}
				}
				&:hover {
					.splatter {
						&.active {
							opacity: 0;
							@include transition(.1s);
						}
						&.hover {
							opacity: 1;
							@include transition(.1s);
						}
					}
				}
			}
			&:hover {
				.splatter {
					&.hover {
						opacity: 1;
						@include transition(.1s);
					}
				}
			}
			&.hide {
				opacity: 0;
				pointer-events: none;
			}
		}

		.nav {
			@include respond-max(991px) { 
				display: none;
			}
		}
		.mobile-nav-wrapper{
			display: none;
			@include respond-max(991px) { 
				display: flex;
			}
			.searchIconWrapper{
				position: relative;
				top: 15px;
				margin-right: 1rem;
				@include respond-max(991px) {
					top: 12px;
				}
			}
			.nav-hamburger {
				width: 29px;
				svg {
					width: 100%;
					display: block;
				}
				.mobile-open {
					width: 100%;
					opacity: 1;
					@include transition(.3s);
					transform: translate(0%, 45%);
				}
				.mobile-close {
					width: 0%;
					opacity: 0;
					@include transition(.3s);
					transform: translate(0%, -45%);
				}

				&.open {
					.mobile-open{
						width: 0%;
						opacity: 0;
						transform: translate(0%, 45%);
					}
					.mobile-close{
						width: 100%;
						opacity: 1;
						transform: translate(0%, -45%);
					}
				}
			}
		}
	}
}

#mainNav {
	@include respond-max($tablet){
		opacity: 0;
	}
}

#sticky-nav {
	position: fixed;
	height: 0px;
	top: 0;
	left: 0;
	z-index: 100;
	overflow: hidden;
	box-sizing: border-box;
	@include transition(.4s);

	&.stuck {
		pointer-events: auto;
		height: 74px;
	}
	
	@include respond-max($tablet) {
		pointer-events: auto;
		height: 74px;
	}
}

#mobile-nav-drawer, #mobile-search-drawer{
 	position: fixed;
	width: 100%;
 	height: 100%;
 	background-color: white;
 	left: 0;
 	top: 74px;
	font-family: 'Spectral', serif;
	font-weight: 400;
	background-image: url('/assets/images/bg-shade_5.png');

  	@include transition(.5s);
 	transform: translateX(-100%);
 	z-index: 2000;
	display: none;

	a {
		color: $black;
	}

 	@include respond-max(991px) { 
		display: block;
	}

	&.open{
		transform: translateX(0%);
	}

	.nav {
		padding-top: 35px;
	}

	.nav-link {
		display: block;
		width: 100%;
		padding-left: 50px;
		font-size: 40px;
	}

	@include respond-max(380px) {
		.nav-link {
			font-size: 32px;
		}
	}
	.search-wrapper {
		margin: 2rem auto;
	}
}

#mobile-search-drawer {
 	transform: translateX(0);
 	transform: translateY(-200%);
 	height: 100px;
 	z-index: 99;

 	.search-wrapper  {
 		width: 80%;
 		form .searchInput {
 			width: 100%;
 		}
		.searchIconWrapper {
			width: 24px;
			height: 24px;

		}
 	}

 	&.open{
		transform: translateY(0%);
	}
	.nav {
		padding-top:0;
	}

}

@keyframes splat {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
    top: 18px;
  }
  80% {
    -webkit-transform: scale(.98);
    transform: scale(.98);
    opacity: 1;
    top: 22px;
  }

  85% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}

@keyframes stickyNavLogoHover {
	100% { left: -640px; }
}



	