#lw {
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	//background: black;
	top:0;
	left:0;
	z-index: 9999;

	&.show{
		display: block;
	}
	#lw-svg {
		width: 80%;
		//height: 80%;
		max-width: 600px;
		max-height: 600px;
		margin: 50vh auto 0;
		transform: translateY(-50%);
		z-index: 9999;
		position: relative;
	}
	#lw-fade-out {
		background: white;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		z-index: 10001;
		position: fixed;
		opacity: 0;
		@include transition(.3s);
		&.fade {
			opacity: 1;
			@include transition(.3s);
		}
	}
	#lw-fade-in {
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		z-index: 9998;
		position: fixed;
		opacity: 0;
		@include transition(.3s);
		&.fade {
			opacity: 1;
			@include transition(.3s);
		}
	}
	#video-gradient {
		position: absolute;
		left: 50%;
		top: 50vh;
		z-index: 10000;
		width: 80%;
		max-width: 600px;
		transform: translate(-50%, -50%);
		opacity: 0;
		@include transition(.3s);
		&.fade {
			opacity: 1;
			@include transition(.3s);
		}
		@include respond-max(750px) {
			top: calc(50vh - 3px);
		}
		svg {
			width: 100%;
			height: 100%;
			linearGradient {
				.video-color {
					stop-color: #d8d0c8;
				}
			}
		}
	}
}