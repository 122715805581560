#header-carousel {
	margin: 0 auto 40px;
	background-image: linear-gradient(white 50%, $greyOne);
	position: relative;
	overflow: hidden;
	@include respond-max(767px) { 
		margin-bottom: 20px;
	}
	.divider{
		position: absolute;
		right: 0;
		top: 50%;
		width: 100%;
		height: 200px;
		background-image: url('/assets/images/divider_2.png');
		background-size: 150% 100%;
		background-position: center;
		@include respond-max(991px) {
			top: 40%;
		}
		@include respond-max(767px) {
			top: 28%;
		}
		@include respond-max(500px) {
			top: 10%;
		}
	}
	.carousel {
		width: 100%;

		a {
			width: 100%;
			height: auto;
			display: block;
			img {
				width: 100%;
			}
		}
	}
	.owl-dots {
		justify-content: center;
		counter-reset: dots;
		text-align: center;
		margin-top: 1rem;
		position: relative;
		z-index: 1;

		span.owl-dot {
			height: 24px;
			margin: 10px auto 0;
			width: 24px;
			@include transition(.1s);
			opacity: .5;

			span {
			    background-image: url('/assets/images/sprites/dot_sprite.svg');
				display: inline-block;
				height: 24px;
				width: 24px;
				background-position: left center;
				background-size: 400%;
			}
			&:focus {
				outline: none;
		    }
		    &.active {
		    	opacity: 1;
		    }
		    &:hover {
		    	@include transition(.1s);
				cursor: pointer;
				opacity: 1;

				span {
					background-image: url('/assets/images/sprites/dot_sprite-red.svg');
					animation: dotHover 0.5s steps(3) infinite;
				}
		    }
		}
	}

}

.carousel-arrows {
	.arrow-inner {
		width: 38px;
		height: 48px;
		position: absolute;
		top: calc(50% - 24px);
		@include respond-max(576px) {
			width: 24px;
		}
		&.left {
			left: -38px;
			@include respond-max(576px) {
				left: -12px;
			}
		}
		&.right {
			right: -38px;
			@include respond-max(576px) {
				right: -12px;
			}
		}
		&:hover {
			cursor: pointer;
			svg {
				animation: spriteHover 0.5s steps(3) infinite;
				fill: $red;
				cursor: pointer;
			}
		}
	}
	.arrow {
		width: 24px;
		height: 24px;
		position: absolute;
		top: calc(50% - 12px);
		overflow: hidden;
		&-left {
			right: 4px;
			transform: rotate(90deg);
			@include respond-max(576px) {
				left: 0;
			}
		}
		&-right {
			left: 4px;
			transform: rotate(-90deg);
			@include respond-max(576px) {
				right: 0;
			}
		}
		svg {
			position: absolute;
			top: 0;
			left: 0;
			height: 24px;
			width: 96px;
			fill: $greyFour;
			&:hover {
				cursor: pointer;
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
}

