
.universal-form {
  padding: 0;
  margin-bottom: 50px;
}

.form-wrapper {
  background: $greyFive;
  background-size: cover;
  background-position: 0 0;
  padding: 35px 22px;
  margin-top: 20px;
  width: 100%;
  .form-title {
    @include spectralBold;
    font-size: 31px;
    line-height: 34px;
    letter-spacing: -2.2px;
    color: $yellow;
    text-align: center;
    display: inline-block;
    margin-bottom: 0;
    @include respond-max(576px) {
      font-size: 28px;
    }
  }
  .form-wrapper-inner {
    max-width: 300px;
    margin: 0 auto;
    position: relative;
    @include respond-max(767px) {
      max-width: 100%;
    }
  }
  &.fifths {
    background-image: url('/assets/images/bg-lines.png');
    @include respond-max(767px) {
      background-image: none;
      background: $greyFive;
    }
  }

  .extra-links {
    justify-content: center;
    display: flex;
    margin-top: 14px;
    a {
      color: $greyFour;
      @include sourceSansRegular;
      font-size: 11px;
      line-height: 14px;
      margin: 0 8px;
    }
  }
  @include respond-max(767px) {
    width: 80%;
    margin: 20px auto 50px;
  }
  @include respond-max(576px) {
    width: 100%;
  }
  .g-recaptcha {
    @include respond-max(374px) {
      -webkit-transform: scale(0.82);
      -moz-transform: scale(0.82);
      -ms-transform: scale(0.82);
      -o-transform: scale(0.82);
      transform: scale(0.82);
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
}

.universal-form {
  &#newsletter-form, &#contact-signup {
    margin-bottom: 0;
    .form-wrapper {
      @include respond-max(767px) {
        margin-bottom: 0px;
      }
    }
  }
}



form {
  .form-group {
    margin-bottom: 10px;
    label {
      @include sourceSansRegular;
      font-size: 12px;
      line-height: 15px;
      color: $greyOne;
      margin-bottom: 4px;
      .bold {
        @include sourceSansBold;
      }
    }
    &.form-check {
      label {
        padding-top: 4px;
        color: $yellow;
        cursor: pointer;
      }
    }
    &.error {
      label {
        color: #DE7349;
      }
      input.form-control, select.form-control, textarea.form-control {
        border: 2px solid $lightRed;
      }
    }
    .g-recaptcha {
      margin-top: 20px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  input.form-control, select.form-control, textarea.form-control {
    border: none;
    border-radius: 0;
    color: $greyFive;
    @include sourceSansRegular;
    font-size: 16px;
    line-height: 20px;
    resize: none;
    opacity: 1;
    &:focus {
      border: none;
      box-shadow:none;
    }
  }

  input.form-control, textarea.form-control {
    &::-webkit-input-placeholder {
      color: $greyFour;
      opacity: .8;
    }
    &::-moz-placeholder {
      color: $greyFour;
      opacity: .8;
    }
    &:-ms-input-placeholder {
      color: $greyFour;
      opacity: .8;
    }
    &:-moz-placeholder {
      color: $greyFour;
      opacity: .8;
    }
  }

  input[type=checkbox] {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 0;
    box-shadow: none !important;
    border: 1px solid $yellow;
    resize: none;
    &:focus {
      outline: none;
      box-shadow:none;

    }
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      opacity: 0;
      background: yellow;
      position: absolute;
      top: 1px;
      left: 1px;
    }
    &:checked  {
      &:before {
        opacity: 1;


      }
    }
    &::-ms-check {
      border-color: $yellow;
      color: $yellow;
      background: none;
    }
  }


  button, .btn {
    width: 100%;
    height: 34px;
    background: $yellow;
    background-image: url('/assets/images/button-splat.png');
    background-size: 200%;
    background-position: 30% 45%;
    @include sourceSansBold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $greySeven;
    border-radius: 0;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    &:hover {
      animation: buttonHover .7s infinite;
    }
  }

  &.success {
    opacity: 0;
  }
}

.form-hint {
  width: 100%;
  color: $lightRed;
  @include sourceSansRegular;
  font-size: 11px;
  opacity: 0;
  height: 20px;
  &.show {
    opacity: 1;
  }
}

.success-message {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: $white;
  opacity: 0;
  pointer-events: none;
  h2, p {
    color: $white;
  }
  &.success {
    opacity: 1;
  }
}


@keyframes buttonGlitch {
  100% {
    background-position: 26% 80%;
  }
}

@keyframes buttonHover {
  0% { background-position: 30% 45%; }

  1% { background-position: 10% 55%; }
  24% { background-position: 10% 55%; }

  25% { background-position: -60% 95%; }
  49% { background-position: -60% 95%; }

  50% { background-position: 60% 15%; }
  74% { background-position: 60% 15%; }

  75% { background-position: 90% 35%;}
  99% { background-position: 90% 35%; }

  100% { background-position: 30% 45%; }
}

