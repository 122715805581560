.detail-grid {
  h2 {
    display: inline-block;
  }
  .toggle {
    line-height: 64px;
    height: 59px;
    @include sourceSansBold;
    font-size: 14px;
    color: #757369;
    text-transform: uppercase;
    margin-left: 20px;
    display: inline-block;
    .hide {
      display: none;
    }
    &:hover {
      cursor: pointer;
      color: $greyFive;
    }
  }
  .grid {
    width: 100%;
    .grid-content-wrapper {
      overflow: hidden;
      @include transition(.2s);
    }

    &-headers {
      @include sourceSansRegular;
      font-size: 14px;
      color: $greySeven;
      line-height: 18px;
      border-bottom: 2px solid $greyFour;
    }
    &-content {
      @include sourceSansRegular;
      font-size: 16px;
      color: $greySeven;
      line-height: 18px;
      padding: 12px 16px;
      background: $greyOne;
      width: calc(100% + 30px);
      display: none;
      &.initial {
        display: flex;
      }
      &:nth-of-type(2n) {
        background: $greyTwo;
      }
      .smaller {
        @include respond-max(768px) {
          font-size: 12px;
        }
      }
      &.author-show {
        display: flex!important;
      }
    }
    .ellipsis {
      display: flex;
      cursor: pointer;
    }
    a.grid-content, .grid-content {
      &:hover {
        text-decoration: none;
        background: $greyThree;
      }
    }
  }
  .ellipsis {
    @include transition(.2s);
  }

  &.full {
    .toggle {
      .hide {
        display: block;
      }
      .show {
        display: none;
      }
    }
    .grid-content-wrapper {
      @include transition(.2s);
    }
    .ellipsis {
      display: none;
      @include transition(.2s);
    }
  }
}