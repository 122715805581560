#newsletter, #contact{
  #newsletter-signup {


    .signup-wrapper-outer {
      margin: 0 auto;
      .signup-wrapper {
        background: none;
      }

    }
  }
}