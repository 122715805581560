.page-header {
	margin-top: 50px;
	@include respond-max(767px) { 
		margin-top: 20px;
	}
	.main-header {
		@include spectralRegular;
		&.large {
			font-size: 3rem;
		}
	}
	.spacer {
		width: 100%;
		height: 57px;
		margin-top: 30px;
		display: block;
	}
	.mobile-sub-nav {
		display: none;
		margin-bottom: .5rem;
		@include respond-max(1000px) {
			display: block;
		}
		.text-link {
			@include sourceSansRegular;

			&.active {
				@include sourceSansBold;
			}
		}
		.circle {
			height: 3px;
			width: 3px;
			background-color: $black;
			border-radius: 50%;
			display: inline-block;
			position: relative;
			bottom: 0.2rem;
			left: 0.2rem;
			margin: 0 .5rem;
		}
	}
	h1 {
		margin-bottom: 0;
		margin-top: 30px;
		@include respond-max($tablet) {
			margin-top: 0;
		}
	}
	.large-header {
		@include spectralLight;
		font-size: 130px;
		color: $greyTwo;
		position: absolute;
		left: -50px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
		@include respond-max(767px) { 
			display: none;
		}
	}
	&.large-only {
		.large-header {
			@include respond-max(767px) { 
				display: block;
				@include spectralRegular;
				font-size: 25px;
				line-height: 32px;
				color: $greySeven;
				left: 0;
				position: relative;
				margin-bottom: 10px;
			}
		}
	}
}