#section-header {
	&.row {
		flex-direction: column;
	}
	.header-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	h2 {
		line-height: 40px;
		margin-bottom: 0px;
	}
	.section-header-links {
		text-align: left;
		margin-bottom: 10px;
		.section-header-link {
			margin-right: 8px;
			padding-right: 12px;
			border-right: solid 1px $greyThree;
			color: #6D6962;
			&:last-of-type {
				border-right: none;
				margin-right: 0;
				padding-right: 0;
			}
		}
	}
	&.full-width {
		h2 {
			line-height: 50px;
		}
		&.row {
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
		}
		.section-header-links {
			text-align: right;
		}
		@include respond-max(991px) { 
			.row, .header-wrapper {
				flex-direction: column;
				margin-bottom: 10px;
				align-items: flex-start;
				display: block;
			}
			h2 {
				line-height: 40px;
				margin-bottom: 0px;
			}
			.section-header-links {
				text-align: left;
				margin-bottom: 10px;
				line-height: 18px;
			}
		}
	}
}
#coming-soon, #latest-news {
	#section-header {
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		     height: 80px;
		}
	}
}

#new-releases {
	#section-header {
		.section-header-links {
			overflow: hidden;
			.header-links-inner {
				margin-left: -21px;
			}
			.section-header-link {
				margin-left: 8px;
				padding-left: 12px;
				border-left: solid 1px $greyThree;
				color: #6D6962;
				margin-right: 0;
				padding-right: 0;
				border-right: none;
			}
		}
	}
}

