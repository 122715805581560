
@mixin dark {
	body {
		background: $greySix;
	}
	h1, h2, h3, h4, h5, p, a.text-link, p.content-date, ol {
		color: $greyOne;
	}

	p.content-header {
		color: $greyFour;
	}

	form .form-group.form-check label {
		color: $red;
	}

	form input[type=checkbox] {
		border-color: $red;
		&:before{
			background-color: $red;
		}
		&::-ms-check {
			border-color: $red;
			color: $red;
		}
	}

	form button, form .btn, .btn {
		background-color: $red;
		color: white;
		background-image: url(/assets/images/button-splat-darkmode.png);
	}

	#mainNav, #sticky-nav, #footer {
		background-color: $greySix;
		background-image: url(/assets/images/darkmode/bg-splat_4.png);

		div.nav .nav-link, .footer-item .footer-link {
			color: white;
		}

		//.site-logo svg g {
		//	fill: $red;
		//}
		div.nav {
			.nav-item.active {
				&.active {
					.splatter {
						&.active {
							opacity: 0;
						}
						&.darkmode {
							opacity: 1;
							@include transition(.1s);
						}
					}
					&:hover {
						.splatter {
							&.darkmode {
								opacity: 0;
								@include transition(.1s);
							}
							&.hover {
								opacity: 1;
								@include transition(.1s);
							}
						}
					}
				}
			}
		}
	}

	#mainNav, #sticky-nav{
		.nav-hamburger {
			.mobile-close, .mobile-open {
				svg g g {
					fill: $greyOne !important;
				}
			}
		}
		div.nav .site-logo {
			.darkmode {
				display: block;
			}
			.lightmode {
				display: none;
			}
		}
	}

	#footer {
		background-image: url('/assets/images/darkmode/bg-splat_4.png');
		.footer-social a {
			color: white;
			&:hover {
				color: $greySix;
			}
		}
	}

	.main-content, .general-content {
		background-image: url('/assets/images/darkmode/bg-splat_4.png');
		background-position: 200% 0%, -20% 8%;
		@include respond-min(1500px) {
			background-position: 150% 100%, -20% 8%;
			background-size: 60%;
		}
		@include respond-min(1910px) {
			background-position: 140% 70%, -20% 8%;
			background-size: 50%;
		}
		&.long {
			background-image: url('/assets/images/darkmode/bg-splat_3.png'), url('/assets/images/darkmode/bg-splat_4.png');
			background-position: 200% 80%, -20% 8%;
			background-size: 80%;
			background-repeat: no-repeat;
		}
	}

	body.layout_home {
		@include respond-min(992px) {
			#mainNav {
				background: $greySeven;
				.site-logo {
					.darkmode {
						display: block;
					}
					.lightmode {
						display: none;
					}
				}
			}
		}

		.main-content {
			background-image: url('/assets/images/darkmode/bg-splat_4.png');
		}
		.main-content ~ .main-content {
			background-image: url('/assets/images/darkmode/bg-splat_4.png');
		}
	}

	#header-carousel {
		background-image: linear-gradient($greySeven, $greySix);
		.divider {
			background-image: url('/assets/images/darkmode/divider_2-blue.png');
			//top: 60%;
		}
		.owl-dots span.owl-dot {
			opacity: .25;
			span {
				background-image: url('/assets/images/sprites/dot_sprite-white.svg');
				&:hover {
					background-image: url('/assets/images/sprites/dot_sprite-red.svg');
					opacity: 1;
					cursor: pointer;
				}
			}
			&.active {
				opacity: 1;
			}
			&:hover {
				opacity: 1;
				cursor: pointer;
				span {
					background-image: url('/assets/images/sprites/dot_sprite-red.svg');
				}
			}
		}
	}

	#newsletter-signup {
		background-color: $red;
		background-image: url('/assets/images/darkmode/bg-splat_3.png');
		background-size: auto 180%;
		@include respond-max($tablet) {
			background-size: auto 160%;
		}
		.big-text p {
			color: $yellow;
		}
		.signup-title {
			color: $red;
		}
		.form-wrapper {
			background: $greySeven;
			.form-title {
				color: $red;
			}
		}
	}

	.content-block-content, #latest-news .news-content {
		background: $black !important;
		.content-blend, .news-blend {
			background: linear-gradient(rgba(0,0,0,0), $black) !important;
		}

	}
	#latest-news.news-page {
		.latest-news {
			border-bottom: 2px solid rgba(255, 255, 255,.3);
		}
		.news-content {
			background: none!important;
			h1 {
				color: $white;
				a {
					color: $white;
					&:hover {
						color: $greyFour;
					}

				}
			}
		}
	}

	.content-block {
		.content-image {
			border-color: $black;
			.content-blur {
				background-color: $black
			}
			.content-splatter {
				&:before {
					background-image: url('/assets/images/darkmode/divider_3.png');

				}
			}
			.content-background {
				background: $black !important;
			}
		}
		&:nth-of-type(2n) {
			.content-image {
				.content-splatter:before {
					background-image: url('/assets/images/darkmode/divider_2.png');
				}
			}
		}
		&:nth-of-type(3n) {
			.content-image {
				.content-splatter:before {
					background-image: url('/assets/images/darkmode/divider_1.png');
				}
			}
		}
		&:nth-of-type(4n) {
			.content-image {
				.content-splatter:before {
					background-image: url('/assets/images/darkmode/divider_4.png');
				}
			}
		}
		&.featured {
			@include respond-min(992px) {
				.content-image {
					border-color: $black;
					.content-splatter {
						&:before {
							background-image: url('/assets/images/darkmode/divider_5.png');

						}
					}
				}
			}
		}
	}
	
	#the-author {
		#about {
		    .content-blend {
		      background: linear-gradient(rgba(0,0,0,0), #000);
		    }
		}
	}

	#faq {
		h6.question {
			color: white;
		}
		.chevron svg, .copy-link svg{
			fill: $greyOne;
		}
		.question-wrapper:hover .chevron svg {
			fill: $greyOne;
		}

	}

	.sidebar {
		a {
			color: $greyOne;
		}
		.secondary-links {
			a.sub-category {
				color: $greyOne !important;
			}
		}
		.sub-categories a {
			&.active {
				color: black;
				@include transition(.1s);
				.splatter {
					&.darkmode {
						opacity: 1;
						@include transition(.1s);
					}
				}
				&:hover {
					color: $white;
					@include transition(.1s);
					.splatter {
						&.hover {
							opacity: 1;
							@include transition(.1s);
						}
						&.darkmode {
							opacity: 0;
							@include transition(.1s);
						}
					}

				}
			}
		}
	}

	#section-header .section-header-links .section-header-link, #new-releases #section-header .section-header-links .section-header-link {
		color: $greyTwo;
	}

	.pagination {
		a {
			color: $white !important;
		}
		.active, a:hover {
			color: $greySeven !important;
		}
	}

	.page-header{
		.large-header {
			color: $greySeven;

		}
	}

	.col-md-12 {
		span.active-sort {
			color: $white;
		}
	}

	.works-inner {
		.work-content {
			p.works-title {
				color: $greyTwo !important;
			}
		}
	}
	.works-page .sort-by {
		.toggle {
			background: $greySeven;
			color: $greyThree;

			.toggle-header .chevron svg g {
				fill: $greyThree;
			}

			.toggle-options {
				.option {
					background: $greySeven !important;
				}
			}
		}

		a.sprite{
			fill: $greyOne !important;
		}

	}
	#works-grid {
		.works-wrapper {
			.works-inner {
				.work {
					.work-image-wrapper {
						background-color: $greySix;
						box-shadow: 0 0 4px rgba(147,146,189,.5);
					}
					&:hover {

						.work-image-wrapper {
							box-shadow: 0 0 12px rgba(147,146,189,.5);
						}
					}
				}
			}
		}
	}
	#works-list {
		.works-wrapper {
			.table-headers {
				.header {
					color: $greyTwo !important;
				}
			}

			.works-inner {
				.work {
					background-color: $darkHover !important;
					.works-title, .works-type, .works-date {
						color: $greyTwo !important;
					}
					&:nth-of-type(2n){
						background-color: $greySeven !important;
					}
					&:hover {
						background-color: $greySix !important;
					}
				}
			}
		}
	}

	.works-page .works-nav-wrapper {
		 background: $greySeven;
		 #works-nav {
			 color: $greyOne;
		 }
		 svg {
		 	 fill: $greyOne;
	 	 }
	}

	.mobile-nav-wrapper {
		.searchIconWrapper svg {
			fill: $greyOne;
		}
	}

	#mobile-nav-drawer, #mobile-search-drawer {
		background-color: $greySix;
		background-image: url('/assets/images/darkmode/bg-splat_4.png');
		a {
			color: $white;
		}
	}

	.search-wrapper  {
		svg {
			fill: $greyOne;	
		}
		.searchInput {
			background: $greySix !important;
			color: $greyOne !important;
			border-color: $greyOne !important;
		}
		&:hover {
			svg {
				fill: $red;	
			}
		}
	}

	.photo-gallery .background-wrapper {
		background: $black;
	}

	.book-detail-page {

		#characters {
			.toggle {
				span {
					color: $greyFour;
				}
				span:hover {
					color: $greyTwo;
				}
			}
		}

		#links {
			span {
				color: $greyTwo;
			}
		}
	}

	.grid-headers{
		div {
			color: $greyTwo !important;
		}
	}

	.grid-content-wrapper {
		color: $greyTwo !important;
	}

	.grid-content {
		color: $greyTwo !important;
		background-color: $darkHover !important;

		&:nth-of-type(2n){
			background-color: $greySeven !important;
		}

		&.grid-headers {
			background-color: transparent !important;
		}

		&:hover {
			background-color: $greySix !important;
		}
	}


	#bibliography {
		.sidebar .sub-categories a .sprite {
			svg g {
				fill: $white;
			}
		}
		h6 {
			color: $white;
		}
		.bibliography-content {
			background-color: $greySeven !important;
			color: $white;
			&:nth-of-type(2n) {
				background-color: $darkHover !important;
			}
			svg {
				fill: $lightRed;
			}
			&:hover {
				background-color: $greySix !important;
				svg {
					animation: spriteHover 0.5s steps(3) infinite;
					fill: $red;
				}
			}
			.title, .format {
		      color: $greyOne;
		    }
		}
	}

	#legal {
		h6 {
			color: white;
		}
		.legal-date {
			color: white;
		}
	}

	.form-wrapper {
		&.fifths {
			background-image: url("/assets/images/darkmode/bg-lines.png") !important;
			@include respond-max(767px) {
				background-image: none;
				background: $greyFive;
			}
		}
	}
	#wipeWrapper #darkMode {
		#tower {
			background-image: url("/assets/images/darkmode/tower.png");
		}
		#circleWipe {
			background: $greyOne;
		}
	}

	#dollar-babies {
		.works-wrapper {
			.works-inner {
				.works-title {
					color: $white;
				}
			}
		}
	}
}


html.dark {
	background: $greySix;
	@include dark;
}

