#the-author {
  .page-header {
      //margin-top: 30px;
  }

  .author {
    margin-bottom: 50px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .author-info {
    width: calc(100% - 300px);
    display: inline-block;
    @include  respond-max($tablet) {
      width: 100%;
      display: block;
      margin-bottom: 1rem;
      .content-content, .content-header {
        display: block;
        margin: 0;
      }
    }
  }

  .author-image {
    width: 275px;
    display: inline-block;
    box-shadow: 0px 5px 20px 0 rgba(49,46,42, .22);
    float: right;
    margin-left: 25px;
    position: relative;
    margin-bottom: 10px;
    @include  respond-max($tablet) {
      width: 100%;
      max-width: 275px;
      float: none;
      margin: 0 auto 1rem auto;
      display: block;
      .content-content, .content-header {
        display: block;
        margin: 0;
      }
    }
    img {
      width: 100%;
      border-radius: 2px;
    }
  }

  #about {
    position: relative;
    .content-blend {
      background: linear-gradient(rgba(255,255,255,0), $greyOne);
      bottom: 1rem;
    }
    .expand-content {
      margin-top: .5rem;
      display: none;
      @include respond-max($tablet){
        display: block;
      }
    }
    .mobile-expand {
      display: block;
      @include respond-max($tablet){
        display: none;
      }
    }
    &.expanded {
      .content-blend, .expand-content {
        display: block;
        @include respond-max($tablet){
          display: none;
        }
      }
    }
  }

  .author-biography {

    #author-form {
      margin-bottom: 0;
      .form-wrapper {
        margin-top: 0;
        @include respond-max(767px) {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .photo-gallery {
    .photo {
      p {
        text-align: left;
        margin: 0;
        &:first-of-type {
          margin-top: 10px;
        }
      }
    }
  }

  .author-appearances {
    .toggle {
      @include respond-max(576px) {
        display: block;
        height: auto;
        line-height: 18px;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }

  .author-awards {
    .grid-headers {
      width: 100%;
      margin: 0 auto;
      background: none;
      padding: 0;
      font-size: 14px;
    }
    .award-header {
      @include spectralBold;
      font-size: 22px;
      letter-spacing: -1px;
      line-height: 30px;
      margin: 1em 0 4px 15px;
      display: none;
      &.initial {
        display: flex;
      }
      &:first-of-type {
        margin-top: 0;
      }
    }
    @include respond-max(767px) {
     .grid-content {
       .work {
         order: 1;
       }
       .smaller {
         order: 2;
       }
     }
    }
  }


}


@keyframes spookyDarkmode {
  0% { opacity: 0; }

  20% { opacity: 0; }
  22% { opacity: .4; }
  24% { opacity: 0; }

  32% { opacity: 0; }
  37% { opacity: .6; }
  42% { opacity: .2; }

  44% { opacity: 1; }
  78% { opacity: 1; }
  82% { opacity: 0; }

  100% { opacity: 0; }
}