.news-content {
	.news-subtext {
		@include sourceSansRegular;
		color: #757369;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: .4px;
		margin-bottom: 8px;
	}

	.news-description {
		position: relative;
	}

	.text-link {
		display: block;
		.fa-youtube {
			font-size: 18px;
			line-height: 19px;
			color: $red;
			vertical-align: middle;
			margin-left: 5px;
		}
	}

	iframe {
		max-width: 100%;
	}
}

#latest-news {
	margin-top: 50px;
	@include respond-max(991px) {
		margin-top: 0px;
	}
	.news-content {
		background: $white;
		padding: 30px 22px;
		margin-bottom: 10px;
		@include respond-max(576px) {
			margin-bottom: 20px;
		}
		h5 {
			margin-bottom: 4px;
		}
		.news-blend {
			width: 100%;
			height: 60px;
			background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
			position: absolute;
			left: 0;
			bottom: 0;
		}
		.news-description {
			max-height: 145px;
			overflow: hidden;
			&.shorter {
				.news-blend {
					display: none;
				}
			}
		}
	}

	&.news-page {
		.latest-news {
			padding-bottom: 20px;
			border-bottom: 2px solid $greyThree;
			margin-bottom: 20px;
			&:last-of-type {
				border-bottom: none;
			}
		}
		.news-content {
			padding: 0 ;
			background: none;

			h1 {
				color: $black;
				margin-bottom: 0;
				a {
					color: $black;
					@include transition(.1s);
					&:hover {
						text-decoration: none;
						color: $greyFour;
						@include transition(.1s);
					}
				}
			}

			.news-description {
				max-height: auto;
			}
			img, iframe {
				margin-bottom: 20px;
				max-width: 100%;
			}
			&:hover {
				@include transition(.2s);
			}
		}

		.works-header-wrapper {
			@include respond-max(767px){
				width: 100%;
			}
		}

		.header-content {
			display: flex;
			justify-content: space-between;
			.sort-by {
				.toggle.open {
					.toggle-options {
						height: 640px;
					}
				}
			}
			@include respond-max(991px) {
				.main-header {
					margin-bottom: 0;
				}
			}
		}

	}
}



