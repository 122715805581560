#legal.legal-page {
  .sub-text {
    width: 95%;
    margin-left: 5%;
    h6 {
      font-size: 1rem;
    }
  }
  .legal-date {
    @include spectralRegular;
    span {
      color: #776F69;
      font-size: .8rem;
      @include spectralBold;
    }
  }
}