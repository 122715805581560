///*** UNIVERSAL DETAIL PAGES ***///

.detail-page {
	margin-top: 80px;
	@include respond-max(767px) {
		margin-top: 20px;
	}
}
.mainContent {
	.breadcrumbs {
		color: #BE4F23;
		a {
			color: #BE4F23;
			@include sourceSansRegular();
			font-size: 14px;
			line-height: 16px;
			text-transform: uppercase;
			position: relative;
			z-index: 1;
			&:hover {
				color: $red;
				@include sourceSansBold();
				text-decoration: none;
			}
		}

	}
}


///*** BOOK DETAIL PAGE ***///

.book-detail-page {
	margin-top: 70px;
	@include respond-max(575px) {
		margin-top: 20px;
	}
	.page-header {
		.large-header {
			display: block;
		}
	}

	.bookDetail {
		margin: 0 auto 50px;
		&-mainContent {
			.image-wrapper {
				width: 100%;
				box-shadow: 0 5px 20px rgba(49,46,42,.22);
				@include respond-min(576px) {
					margin-top: 8px;
				}
				img {
					width: 100%;
					border-radius: 2px;
				}
				&.mobile-show {
					width: 145px;
					margin-right: 15px;
					margin-bottom: 20px;
				}
			}
			.right {
				@include respond-max(575px) {
					width: calc(100% - 165px);
					//margin-bottom: 20px;
					.content-header {
						margin-bottom: 0;
						width: 100%;
						margin-right: 0;
					}
				}
			}
			.mobile-styling {
				@include respond-max(575px) {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					h1 {
						width: 100%;
					}
				}
			}
		}

		&-alternateCovers {
			&.photo-gallery {
				margin: 70px auto;
			}
		}

		&-inspiration {
			@include respond-min(769px) {
				p {
				-webkit-column-count: 2;
		      	-moz-column-count: 2;
		       	column-count: 2;

		   		-webkit-column-gap: 20px;
		      	-moz-column-gap: 20px;
		       	column-gap: 20px;
		       	}
			}
		}

		&-fromTheFlap {
			@include respond-min(769px) {
				p {
				-webkit-column-count: 2;
		      	-moz-column-count: 2;
		       	column-count: 2;

		   		-webkit-column-gap: 20px;
		      	-moz-column-gap: 20px;
		       	column-gap: 20px;
		       	}
			}
		}

		&-links {
			margin-bottom: 0;
			.extra-link {
				color: $greyFive;
				@include sourceSansBold;
				font-size: 14px;
				line-height: 45px;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				span {
					margin-left: 12px;

				}
				.icon {
					width: 50px;
					text-align: center;
				}
				img {
					height: 25px;
					margin: 0 auto;
					@include transition(.2s);
				}
				&:hover {
					text-decoration: none;
					img {
						transform: scale(1.15);
						@include transition(.2s);
					}
				}
			}
		}

		&-related {
			position: relative;
			margin-bottom: 0;
			.related-works {
				display: flex;
				@include respond-max(576px) {
					padding: 0 10px;
				}
			}
			a {
				margin-right: 20px;
				img {
					width: auto;
					height: 168px;
				}
				p {
					@include sourceSansRegular;
					font-size: 14px;
					line-height: 18px;
					text-align: center;
					margin-top: 10px;
				}
				&:hover {
					text-decoration-color: $greyFour;
				}
			}
			.related-works-arrows {
				.arrow-inner {
					&.left {
						@include respond-max(576px) {
							left: 0;
						}
					}
					&.right {
						@include respond-max(576px) {
							right: 0;
						}
					}
				}
			}
		}
	}
}


///*** NEWS DETAIL PAGE ***///
.news-detail-page {
	margin-bottom: 50px;
}