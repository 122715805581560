.generic-section {
  margin-bottom: 50px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.image-block {
  margin-top: 20px;
  width: 100%;
  .caption {
    font-size: 14px;
    line-height: 19px;
    margin-top: 5px;
  }
  img {
    max-width: 100%;
  }
}

.pagination {
  justify-content: center;
  margin: 20px auto 0px;
  .paginate {
    width: 28px;
    height: 28px;
    border-radius: 3px;
    margin: 0 1px;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    @include sourceSansRegular;
    border: 1px solid $greyTwo;
    color: $greySeven;
    &.active {
      border-color: $greyThree;
      background: $greyTwo;
    }
    &:hover {
      text-decoration: none;
      background: $greyTwo;
      &.active {
        background: $greyThree;
      }
    }
  }
}