#dollar-babies {
  .works-wrapper {
    .works-inner {
      //max-height: 100px;
      margin-bottom: 10px;
      column-count: 3;
      column-gap: 40px;
      display: block;
      @include respond-max(767px) {
        column-count: 2;
        column-gap: 20px;
      }
      @include respond-max(576px) {
        column-count: 1;
        column-gap: 0px;
      }
      .works-title {
        color: $black;
        font-size: 18px;
        @include spectralBold;
        text-decoration: none;
        display: block;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}