.clear {
	clear: both;
}

html {
	@include respond-max($tablet) { 
		overflow-x: hidden;
	}
}

body {
	background: $greyOne;
	scroll-behavior: smooth;
	overflow-x: hidden;
	position: relative;
}
.container {
	@include respond-max(576px) { 
		padding: 0;
	}
}

#sk-ad {
	margin: 50px auto;
	img {
		width: 100%;
	}
}


.main-content {
	background-image: url('/assets/images/bg-shade_5.png');
	background-position: 200% 80%;
	background-size: 80%;
	background-repeat: no-repeat;

	&.long {
		background-image: url('/assets/images/bg-shade_5.png'), url('/assets/images/bg-shade_5.png');
		background-position: 200% 80%, -20% 8%;
		background-size: 80%;
		background-repeat: no-repeat;
	}
}

.content-blend {
	width: 100%;
	height: 60px;
	background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
	position: absolute;
	left: 0;
	bottom: 0;
}

.general-content {
	background-image: url('/assets/images/bg-shade_5.png');
	background-position: 200% 80%;
	background-size: 80%;
	background-repeat: no-repeat;
	p {
		@include spectralLight;
		.bold {
			@include spectralBold;
		}
	}
}

.image-zoom {
	cursor: pointer;
}

#black-out {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: black;
	z-index: -1;
	opacity: 0;
	text-align: center;
	&.animate{
		@include transition(.3s)
	}
	&.lightbox {
		background-color: rgba(37, 34, 49, .9);
		opacity: 1;
		z-index: 1001;
		.image-viewer {
			display: inline-block;
		}
	}
	.image-viewer {
		height: 80%;
		position: relative;
		margin: 0 auto;
		top: 10%;
		display: none;
		@include respond-max($tablet) {
			height: initial;
		}
		img {
			height: 100%;
    		width: auto;
		}
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			opacity: 1;
			background: url('/assets/images/line_9.png');
			background-repeat: no-repeat;
		    background-size: 100% 100%;
		    background-position: center;
		    width: 38px;
		    height: 38px;
			@include transition(.2s);
			.sprite-wrapper {
				width: 24px;
				height: 24px;
				overflow: hidden;
				position: relative;
				top: 2px;
				margin: 0 auto;
			}
			svg {
    			height: 24px;
    			width: 96px;
    			left: 0;
    			position: relative;
				g{
					fill: $greyOne;
				}
			}

			&:hover {
				svg {
					animation: spriteHover 0.5s steps(3) infinite;
				}
			}
		}
		@include respond-max($tablet) {
			width: 80%;
			img {
				height: auto;
    			width: 100%;
			}
		}
	}
}
.searchIconWrapper {
	width: 24px;
	height: 24px;
	display: block;
	position: absolute;
	top: 5px;
	left: 0;
	overflow: hidden;
	cursor: pointer;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		height: 24px;
		width: 96px;
	}
	@include respond-max(991px) {
		width: 32px;
		height: 32px;
		svg {
			height: 32px;
			width: 128px;
		}
	}
}
.search-wrapper {
	width: 24px;
	height: 34px;
	display: block;
	position: relative;
	overflow: hidden;
	@include transition(.3s);
	svg {
		position: absolute;
		top: 0;
		left: 0;
		height: 24px;
		width: 96px;
	}
	.searchInput {
		border: none;
		opacity: 0;
		outline: none;
		border-bottom: 3px solid black;
		@include sourceSansRegular;
	}
	
	.arrowWrapper {
		right: 0;
		top: 0;
		width: 24px;
		height: 24px;
		display: none;
		opacity: 0;
		position: absolute;
		overflow: hidden;
		cursor: pointer;
	}
	&:hover {
		svg {
			animation: spriteHover 0.5s steps(3) infinite;
			fill: $red;
		}
	}

	&.expanded {
		width: 200px;
		padding-left: 30px;
		form {
			position: relative;
			@include transition(300ms);
			.searchInput {
				width: 185px;
				opacity: 1;
				padding: 0 48px 4px 4px;
				margin-left: 2px;
			}
		}

		.glyph-arrow-btn {
			opacity: 1;
		}
		.arrowWrapper {
			display: block;
			opacity:1;
		}
	}
	@include respond-max(991px) {
		width: 200px;
		padding-left: 30px;
		form {
			position: relative;
			@include transition(300ms);
			.searchInput {
				width: 185px;
				opacity: 1;
				padding: 0 48px 4px 4px;
				margin-left: 2px;
			}
		}

		.glyph-arrow-btn {
			opacity: 1;
		}
		.arrowWrapper {
			display: block;
			opacity:1;
		}
	}
}

.not-found {
	@include respond-max($tablet) {
		.container.py-5{
			padding: .75rem 0 0 0 !important;
		}
		.main-header, p {
			text-align: center;
		}
	}
	.text-link{
		display: inline-block;
		color: $greyFour;
		position: relative;
		.chevron {
	      width: 20px;
	      height: 20px;
	      overflow: hidden;
	      position: relative;
	      display: inline-block;
	      transform: rotate(90deg);
	      top: 5px;
	      svg {
	        position: absolute;
	        top: 0;
	        left: 0;
	        height: 20px;
	        width: 80px;
	        fill: $greyFour;
	      }
	    }
	    &:hover {
			.chevron svg {
			  animation: smallSpriteHover 0.5s steps(3) infinite;
			  @include respond-max($tablet) {
			    animation: none;
			  }
			}
		}
	}
	.search-wrapper{
		width: 300px;
		max-width: 90%;
		form {
			position: relative;
			@include transition(300ms);
			.searchInput {
				width: 185px;
				opacity: 1;
				padding: 0 48px 4px 4px;
				margin-left: 2px;
			}
		}
		.glyph-arrow-btn {
			opacity: 1;
		}
		.arrowWrapper {
			display: block;
			opacity:1;
		}
		form .searchInput {
			width: 100%;
		}
		@include respond-max($tablet) {
			width: 90%;
		    max-width: 300px;
		    margin: 0 auto;
		    padding-left: 0;
		}
	}
	img {
		display: block;
		width: 100%;
		@include respond-max($tablet) {
			width: 80%;
    		margin: 2rem auto;
		}
	}
	h1 {
		line-height: 1em;
	}
}

.flash-notice {
	opacity:0;
	z-index: -1;
	pointer-events: none;
	@include transition(.3s);
	padding: .5rem 1rem;
	position: fixed;
	top: 100px;
	right: 2rem;
	@include spectralBold;
	border-radius: 10px;
	background:  $greyTwo;
	@include respond-max($tablet) {
		width: 200px;
		margin-left: 100px;
		right: 50%;
	}

	&.flash {
		opacity:1;
		z-index:1001;
	}
}

#wipeWrapper {
    position: absolute;
    bottom: 1rem;
    right: 4rem;
    z-index: 99;
    &.expand {
		width: 100%;
     	top: 0;
     	right: 0;
     	overflow: hidden;
     	z-index: 1100;
    }
    @include respond-max(700px) {
		right: 50%;
		margin-right: -90px;
		bottom: 50px;
		&.expand {
			right: 0;
	     	margin-right: 0;
	     	overflow: hidden;
			#darkMode {
				position: absolute!important;
				bottom: 50px!important;
				right: 50%!important;
				margin-right: -90px!important;

			}

	    }
	}
	@include respond-max(576px) {
		bottom: 60px;
		&.expand {
			#darkMode {
				bottom: 60px!important;
			}

		}
	}

    #darkMode {
		position: relative;
		display: block;
		&.anchor {
			position: absolute;
			bottom: 1rem;
    		right: 4rem;
    		@include respond-max(700px) {
				right: 50%;
				margin-right: -130px;
			}
		}
		#tower {
			width: 255px;
			height: 248px;
			background-size: cover;
			background-image: url("/assets/images/tower.png");
			@include respond-max(700px) {
				width: 204px;
				height: 198.4px;
			}
		}
		#beams {
			opacity: 0;
			width: 553px;
			position: absolute;
			top: -50px;
			left: calc(50% - 255px);
			pointer-events: none;	
			@include transition(.2s);
			@include respond-max(700px) {
				width: 442px;
				left: calc(50% - 205px);
				top: -40px;
			}
			@include respond-max($tablet) {
				opacity: 13
			}
		}
		&:hover {
			#beams{
				opacity: 1;
			}
		}
		#circleWipe {
			width: 200px;
			height: 200px;
			border-radius: 200px;
			opacity: .8;
			position: absolute;
			top: -45%;
			right: calc(50% - 116px);
			background: $greySix;
			transform: scale(0);
			&.show {
				opacity: 1;
				@include transition(.01s);
			}
			&.fade {
				opacity: 0;
				@include transition(.5s);
			}
			&.wipe {
				@include transition(1s);
				transform: scale(18);
			}
			&.dark {
				background: $greySix !important;
			}
			&.light {
				background: $greyOne !important;
			}
		}
	}
}

@keyframes spriteMediumHover {
	100% { left: -96px; }
}

@keyframes spriteHover {
   100% { left: -72px; }
}

@keyframes smallSpriteHover {
	 100% { left: -60px; }
 }
@keyframes smallestSpriteHover {
	100% { left: -45px; }
}

@keyframes dotHover {
	100% { background-position: right center;}
}

.mobile-hide {
	@include respond-max(575px) {
		display: none;
	}
}

.mobile-show {
	display: none;
	@include respond-max(575px) {
		display: block;
	}
}

.tablet-hide {
	@include respond-max(767px) {
		display: none;
	}
}

.tablet-show {
	display: none;
	@include respond-max(767px) {
		display: block;
	}
}

