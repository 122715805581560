#footer {
	background-color: $white;
	background-image: url('/assets/images/bg-shade_2.png');
	background-repeat: no-repeat;
	background-size: 80%;
	background-position: 200% 75%;
	padding: 38px 0 70px;
	position: relative;
	z-index: 2;
	@include respond-min(1200px) { 

	}
	@include respond-max(700px) {
		padding-bottom: 280px;
	}

	.footer-link-wrapper {
		div {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			max-height: 100px;
			margin-bottom: 1rem;
			padding-left: 0;
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			 height: 200px;
			}
		}
		@include respond-max(700px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.footer-item {
		list-style-type: none;
		width: calc(100% / 3);

		.footer-link {
			color: $greySix;
			font-size: 13px;
			line-height: 19px;
			@include sourceSansRegular;
			&:hover {
				@include sourceSansBold;
				text-decoration: none;
			}
		}
		@include respond-max(700px) {
			text-align: center;
		}
	}

	.footer-social {
		display: flex;
		justify-content: center;
		margin: 0px 0 20px;
		@include respond-max(767px) { 
			margin: 0px auto 20px;
		}
		a {
			margin: 0 24px 0 0;
			font-size: 24px;
			line-height: 24px;
			color: $greySix;
			@include transition(.1s);
			position: relative;
			&:last-of-type {
				margin-right: 0;
			}
			@include respond-max(767px) {
				width: 24px;
				text-align: center;
			}
			i {
				position: relative;
              	z-index: 1;
			}
			.dot {
				position: absolute;
				left: calc(50% - 30px);
				top: calc(50% - 32px);
				width: 60px;
				height: 60px;
				transform: scale(.5);
				opacity: 0;
				@include transition(.1s);
				svg {
					width: 100%;
					height: 100%;
					g {
						fill: $red;
					}
				}
				//&.two {
				//	display: none;
				//}
			}
			&:hover {
				color: $white;
				@include transition(.1s);
				text-decoration: none;
				.dot {
					transform: scale(1);
					opacity: 1;
					&.one {
						animation: footerSocialHover1 .7s infinite;
                      	animation-delay: .1s;
					}
					&.two {
						animation: footerSocialHover2 .7s infinite;
						animation-delay: .1s;
					}
				}
			}
		}
	}
	.social{
		margin:0;
	}
	.links {
		@include respond-max(576px) {
			margin: 0;
		}
	}

}

.copyright {
	margin: 20px auto 10px;
	@include respond-max(700px) {
		justify-content: center;
	}
	&.desktop {
		@include respond-max(700px) {
			display:none;
		}
	}
	&.mobile {
		display: none;
		@include respond-max(700px) {
			display:flex;
			position: absolute;
			bottom: 0;
			z-index: 2;
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
		}
	}
	.footer-copyright {
		color: #7F746E;
		font-size: 11px;
		line-height: 14px;
		@include sourceSansRegular;
		margin-right: 40px;
		@include respond-max(576px) {
			display: block;
			width: 100%;
			margin: 0 0 10px;
			text-align: center;
		}
	}
	a {
		&:hover {
			text-decoration: none;
			@include sourceSansBold;
		}
		&.footer-copyright {
			@include respond-max(700px) {
				margin-right: 0;
			}
		}
	}
}

@keyframes footerSocialHover1 {
	0% { transform: rotate(0deg); }
	1% { transform: rotate(90deg); }
	24% { transform: rotate(90deg); }
	25% { transform: rotate(180deg); }
	49% { transform: rotate(180deg); }
	50% { transform: rotate(270deg); }
	74% { transform: rotate(270deg); }
	75% { transform: rotate(320deg); }
	99% { transform: rotate(320deg); }
	100% { transform: rotate(0deg); }

}
@keyframes footerSocialHover2 {
	0% { transform: rotate(0deg); }
	1% { transform: rotate(-110deg); }
	24% { transform: rotate(-110deg); }
	25% { transform: rotate(-180deg); }
	49% { transform: rotate(-180deg); }
	50% { transform: rotate(-240deg); }
	74% { transform: rotate(-240deg); }
	75% { transform: rotate(-300deg); }
	99% { transform: rotate(-300deg); }
	100% { transform: rotate(0deg); }
}