.works-page {
	margin-bottom: 50px;

	.works-header-wrapper {
		@include respond-max(767px){
			width: 100%;
		}
	}




	.main-header {
		display: inline-block;
		@include respond-max(991px) {
			display: none;
		}
	}
	.active-sort {
		font-size: 20px;
		@include spectralRegular;
		line-height: 30px;
		display: inline-block;
		@include respond-max(991px) {
			display: none;
		}
	}
	.header-content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@include respond-max(991px) {
			align-items: center;
			margin-bottom: 20px;
		}
		@include respond-max(767px) {
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 0;
		}
	}

	&#works-list {
		.sort-by {
			.sort-grid {
				svg {
					opacity: .45;
				}
				&:hover {
					svg {
						opacity: 1;
					}
				}
			}
		}
		.works-wrapper {
			margin-top: 20px;
			.table-headers {
				border-bottom: solid 2px $red;
				width: 100%;
				margin: 0 auto;
				.header {
					line-height: 16px;
					font-size: 13px;
					padding-bottom: 4px;
					@include sourceSansRegular;
					&.publish-date {
						text-align: right;
					}
				}
			}
			.works-inner {
				.work {
					line-height: 20px;
					font-size: 16px;
					@include sourceSansRegular;
					padding: 10px 0px;
					background-color: $greyOne;
					@include transition(.1s);
					color: $black;
					width: 100%;
					margin: 0 auto;
					&:nth-of-type(2n) {
						background-color: $greyTwo;
						@include transition(.1s);
					}
					.works-date {
						text-align: right;
					}
					&:hover {
						text-decoration: none;
						background-color: $greyThree;
						@include transition(.1s);
					}
					@include respond-max(576px) { 
						.works-date, .works-type {
							font-size: 13px;
						}
					}
				}
			}
		}
		.pagination {
			margin: 50px auto 0px;
		}
	}
	&#works-grid {
		.sort-by {
			.sort-list {
				svg {
					opacity: .45;
				}
				&:hover {
					svg {
						opacity: 1;
					}
				}
			}
		}
		.pagination {
			margin: 30px auto 0px;
		}
	}

}

.works-wrapper {
	@include respond-max(768px) {
		margin-top: 20px;
	}
	.works-inner {
		display: flex;
		flex-wrap: wrap;
		.work {
			width: calc(20% - 16px);
			margin: 0 20px 30px 0;
			@include transition(.1s);
			color: $greyThree;
			&:nth-of-type(5n) {
				margin-right: 0;
			}

			@include respond-max(768px) {
				width: calc(25% - 15px);
				&:nth-of-type(5n) {
					margin-right: 20px;
				}
				&:nth-of-type(4n) {
					margin-right: 0;
				}

			}

			@include respond-max(576px) {
				width: calc(50% - 10px);
				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}

			.work-image-wrapper {
				position: relative;
				width: 100%;
				padding-top: 150%;
				overflow: hidden;
				margin-bottom: 10px;
				background-color: $greyTwo;
				border-radius: 2px;
				box-shadow: 0 0 4px rgba(00,00,00,.3);
				@include transition(.2s);
				.work-image {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-size: cover;
					background-position: center;
				}
			}

			.work-content {
				.works-title {
					color: $black;
					line-height: 22px;
					font-size: 16px;
					@include spectralBold;
					margin-bottom: 2px;
					text-decoration: none;
				}
				.works-type {
					line-height: 16px;
					font-size: 13px;
					@include sourceSansBold;
					color: #6D6962;
					margin: 3px 0 0;
					letter-spacing: .1px;
					text-decoration: none;
				}
				.works-date {
					line-height: 16px;
					font-size: 13px;
					@include sourceSansRegular;
					color: #6D6962;
					margin: 3px 0 0;
					letter-spacing: .1px;
					text-decoration: none;
				}
			}

			&:hover {
				text-decoration: none;
				.works-title {
					text-decoration: underline;
					text-decoration-color: $greyFour;
				}
				.work-image-wrapper {
					transform: scale(1.03);
					@include transition(.5s);
					box-shadow: 0 0 12px rgba(00,00,00,.5);
					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						transform: scale(1);
					}
				}
			}
		}
	}
}

.sort-by {
	display: flex;
	flex-direction: row;
	height: 26px;
	align-items: center;
	z-index: 10;
	margin-top: 2px;
	@include respond-max(576px) {
		width: 100%;
	}
	.sprite {
		width: 24px;
		height: 24px;
		overflow: hidden;
		margin-left: 16px;
		position: relative;
		svg {
			position: absolute;
			top: 0;
			left: 0;
			height: 24px;
			width: 96px;
		}
		&:hover {
			svg {
				animation: spriteHover 0.5s steps(3) infinite;
				fill: $red;
			}
		}
		@include respond-max(576px) {
			&.sort-grid {
				margin-left: 0;
			}
		}
	}

	.toggle {
		background: $greyTwo;
		height: 26px;
		padding: 1px 10px;
		margin-left: 16px;
		display: flex;
		position: relative;
		&:first-of-type {
			margin-left: 0;
		}
		.toggle-header {
			align-items: center;
			display: flex;
			width: 100%;
			.toggle-title {
				line-height: 24px;
				font-size: 13px;
				@include sourceSansRegular;
				display: inline-block;
				margin-right: 5px;
			}
			.chevron {
				width: 20px;
				height: 20px;
				overflow: hidden;
				position: relative;
				display: inline-block;
				transform: rotate(0deg);
				@include transition(.1s);
				svg {
					position: absolute;
					top: 0;
					left: 0;
					height: 20px;
					width: 80px;
				}
			}
			&:hover {
				cursor: pointer;
				svg {
					animation: smallSpriteHover 0.5s steps(3) infinite;
					fill: $red;
				}
			}
		}

		.toggle-options {
			position: absolute;
			width: 100%;
			top: 26px;
			left: 0;
			height: 0;
			overflow: hidden;
			@include transition(.3s);
			.option {
				width: 100%;
				padding: 4px 0px;
				background: $greyTwo;
				line-height: 24px;
				font-size: 14px;
				text-align: center;
				@include sourceSansRegular;
				@include transition(.1s);
				&:hover {
					background: $greyThree;
					cursor: pointer;
					@include transition(.1s);
				}
			}
		}
		&.open {
			.toggle-options {
				height: 64px;
				@include transition(.3s);
				@include respond-max(576px) {
					height: 128px;
				}
			}
			.chevron {
				transform: rotate(180deg);
				@include transition(.1s);
			}
		}

		&.sort-mobile {
			display: none;
			margin-left: auto;
		}
		@include respond-max(576px) {
			&.sort-mobile {
				display: block;
			}
			&.sort-title, &.sort-date {
				display: none;
			}
		}
	}
}

.works-nav-wrapper {
	position: relative;
	background: $greyTwo;
	padding: 5px 10px;
	max-width: 290px;
	display: none;
	@include respond-max(991px){
		display: block;
	}
	@include respond-max(767px) {
		width: 100%;
		max-width: 100%;
		margin-bottom: 8px;
	}


	#works-nav{
		//display: none;
		background: none;
		border: none;
		-webkit-appearance: none;
		appearance: none;
		@include spectralRegular;
		font-size: 25px;
		line-height: 32px;
		color: $greySeven;
		width: 100%;
		//margin-bottom: .5rem;
		&::-ms-expand {
			display: none;
		}
		span.active-sort {
			display: block;
		}
		@include respond-max(576px){
			right: 0;
		}
		@include respond-max($tablet) {
			display: block;
		}
	}
	.chevron {
		width: 20px;
		height: 20px;
		overflow: hidden;
		position: absolute;
		display: inline-block;
		transform: rotate(0deg);
		top: 12px;
		right: 10px;
		@include transition(.1s);
		svg {
			position: absolute;
			top: 0;
			left: 0;
			height: 20px;
			width: 80px;
		}
		@include respond-max(991px) {
			pointer-events: none;
		}
	}
}