#bibliography {

  .sort-by {
    .sort-grid, .sort-list {
      display: none;
    }
  }

  .header-content {
    @include respond-max(768px) {
      margin-bottom: 20px;
    }
  }

  .sidebar {
    .sub-categories {
      a {
        position: relative;
        height: 32px;
        .sprite {
          width: 15px;
          height: 15px;
          overflow: hidden;
          position: absolute;
          margin-top: 9px;
          left: -17px;
          z-index: 1;
          svg {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 60px;
          }

        }
        .splatter {
          left: calc(50% - 8px);
          &.one {
            top: -3px;
          }
          &.two {
            top: -1px;
          }
          &.three {
            top: 3px;
          }
          &.darkmode {
            display: none;
          }
        }
        &.active, &:hover {
          .splatter {
            &.one {
              width: 190px;
              @include respond-max(1200px) {
                width: 120px;
              }
            }
            &.two {
              width: 145px;
            }
            &.three {
              width: 100px;
            }
          }
        }
        &:hover {
          svg {
            animation: smallestSpriteHover 0.5s steps(3) infinite;
            fill: $white;
          }
        }
      }

    }
  }

  .bibliography-section {
    margin-bottom: 25px;
    h6 {
      margin-bottom: 4px;
    }
  }

  .bibliography-content {
    line-height: 20px;
    font-size: 16px;
    @include sourceSansRegular;
    padding: 10px 16px;
    background-color: $greyTwo;
    @include transition(.1s);
    color: $black;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &:nth-of-type(2n) {
      background-color: $greyOne;
      @include transition(.1s);
      .format {
        font-size: 14px;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: $greyThree;
      @include transition(.1s);
      svg {
        animation: spriteHover 0.5s steps(3) infinite;
        fill: $red;
      }
      .title, .format {
        color: #DE3E16;
      }
    }

    span {
      margin-right: 4px;
      flex-shrink: 0;
    }

    .sprite {
      width: 24px;
      height: 24px;
      overflow: hidden;
      position: relative;
      margin-right: 8px;
      svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 96px;
      }

      @include respond-max(576px) {
        &.sort-grid {
          margin-left: 0;
        }
      }
    }

    .author {

    }
    .title {
      color: black;
      text-decoration: underline;
      @include sourceSansBold;
    }
    .location {

    }
    .publisher {
      text-decoration: underline;
    }
    .date {
    }
    .format {
      color: black;
      font-size: 14px;
    }

    @include respond-max(576px) {

    }
  }

}